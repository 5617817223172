<script setup>
import { ref } from "vue";
import { fetchGames, fetchProvider } from "../api";
import GameList from "@/casino/components/GameList.vue";

const props = defineProps({
  slug: {
    type: String,
    required: true,
  },
});
const provider = ref(null);
const games = ref([]);

const pagination = ref({
  page: 1,
  rowsPerPage: 100,
});

async function loadGames($state) {
  try {
    const { data } = await fetchGames({
      page: pagination.value.page,
      per_page: pagination.value.rowsPerPage,
      provider_id: provider.value.id,
    });

    games.value.push(...data.data);

    if (data.data.length < pagination.value.rowsPerPage) {
      $state.complete();
    } else {
      $state.loaded();
    }
    pagination.value.page++;
  } catch (error) {
    $state.error();
  }
};

async function updateData() {
  const { data } = await fetchProvider(props.slug);

  provider.value = data.data;
}

updateData();
</script>

<template>
  <article v-if="provider">
    <GameList :name="provider.name" :games="games" @load="loadGames" />
  </article>
</template>

<style scoped>
.casino-view {
  width: 100%;
  min-height: 70vh;
}
</style>
