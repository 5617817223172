<script setup>
import { ref } from "vue";
import VueHorizontal from "vue-horizontal";
import ProviderCard from "./ProviderCard.vue";
import BaseIcon from "@/app/components/ui/BaseIcon.vue";
import "@splidejs/vue-splide/css";
import { useI18n } from "@/app/composables/useI18n";
import { router } from "@/app/router";

defineProps({
  providers: {
    type: Array,
    required: true,
  },
});

const { t } = useI18n();

function handleViewAll() {
  router.push({ path: "/casino/providers" });
}

const horizontal = ref(null);

function prevSlide(event) {
  if (horizontal.value && typeof horizontal.value.prev === "function") {
    horizontal.value.prev(event);
  }
}

function nextSlide(event) {
  if (horizontal.value && typeof horizontal.value.next === "function") {
    horizontal.value.next(event);
  }
}
</script>

<template>
  <section v-if="providers.length > 0" class="list-wrapper">
    <div class="list-header">
      <div class="list-title">
        <BaseIcon name="providers" size="20" color="var(--be-disable-state)" />
        <span>
          {{ t("casino.providers") }}
        </span>
      </div>
      <div
        class="list-view"
        @click="handleViewAll"
      >
        <div>{{ t("general.view-all") }}</div>
        <div @click="(event) => prevSlide(event)" :class="horizontal?.hasPrev ? '' : 'dim'">
          <img src="/assets/images/left-arrow-base-sliding.svg" alt="" />
        </div>
        <div @click="(event) => nextSlide(event)" :class="horizontal?.hasNext ? '' : 'dim'">
          <img src="/assets/images/right-arrow-base-sliding.svg" alt="" />
        </div>
      </div>
    </div>

    <div class="base-slide">
      <VueHorizontal
        ref="horizontal"
        :button="false"
      >
        <div v-for="provider in providers" :key="provider.id">
          <ProviderCard :provider="provider" />
        </div>
      </VueHorizontal>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.base-slide {
  padding: 0 16px 16px 16px;
}

.dim {
  opacity: 0.3;
}

@media screen and (max-width: 550px) {
  .base-slide {
    padding: 0px;
  }
}

.list-wrapper {
  margin: 10px 0px;
  background-color: var(--be-big-card-bg);
  border-radius: 8px;

  .list-header {
    display: flex;
    justify-content: space-between;
    margin-right: 16px;
    margin-bottom: 10px;
    padding-top: 16px;
  }

  .list-view {
    display: flex;
    gap: 0 20px;
    align-items: center;

    font-size: 16px;
    cursor: pointer;
    color: var(--be-primary-btn);
    font-family: Rubik;
  }

  .list-title {
    display: flex;
    align-items: center;
    margin: 0 0 4px 19px;
    font-size: 16px;
    font-family: Rubik-med;
    color: var(--be-secondary-btn-label);

    img {
      max-width: 24px;
      max-width: 24px;
      height: 24px;
    }

    span {
      margin: 4px 0 0 8px;
    }
  }
}

@media screen and (max-width: 550px) {
  .list-wrapper {
    background-color: transparent;

    .list-header {
    margin-right: 0px;
  }
    .list-title {
      margin: 0 0 4px 0px;
    }
  }
}
</style>
