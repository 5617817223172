import { computed, reactive, ref, shallowReactive } from "vue";
import { useRoute } from "vue-router";
import { api } from "@/services";

const categoriesSpecial = [
  {
    id: 0,
    name: "Lobby",
    slug: "home",
  },
  {
    id: 0,
    name: "Providers",
    slug: "providers",
  },
];

const state = reactive({
  category: categoriesSpecial[0], // initial category
  providers: [],

  selectedProviders: [],
  searchText: "",
});

const category = ref({ id: 0 });
const categories = ref([]);
const providers = ref([]);
const allGames = ref([]);

const loading = ref(false);
let inited = false;

const categoriesExtended = ref([]);

const games = computed(() => {
  if (category.value?.slug === "providers") {
    return [];
  }
  return Object.keys(state.selectedProviders).length === 0 ? allGames.value : allGames.value;
});

export function useCasinoService() {
  const route = useRoute();
  async function initCasino(id) {
    loading.value = true;

    categories.value = await api.casino.categories();

    loading.value = false;
    categoriesExtended.value = categoriesSpecial.concat(categories.value || []);
    inited = true;

    setRouteWatch(id);
  }

  async function setRouteWatch(ids) {
    let isBackFromGame = false;

    if (route?.name.startsWith("casino")) {
      if (route.name !== "casino.game") {
        state.selectedProviders = Object.keys(ids || {}).length != 0 ? Object.keys(ids || {}) : [];

        state.category = categoriesExtended.value.find((c) => c.slug === route.params?.category);
        if (!state.category) {
          if (route.name === "casino.provider") {
            state.category = categoriesExtended.value[1];
            state.selectedProviders = Object.keys(ids);
          } else {
            state.category = categoriesExtended.value[0];
          }
        }
        await updateGames(isBackFromGame);
      }
      const s = {};
      s.c = state.category.id;
      s.p = state.providers.length;
      s.sp = state.selectedProviders;
      s.st = state.searchText;
    }
  }

  async function updateGames(isBackFromGame = false) {
    if (!inited) {
      return;
    }

    providers.value = await api.casino.providers(state.category.id);

    const params = {
      categoryId: state.category.id,
      providers: state.selectedProviders,
      searchText: state.searchText,
      status: true,
      lobby: state.category.id == 0 ? true : undefined,
      paginator: {
        cp: 1,
        rpp: 1000,
      },
    };

    params.device = window.innerWidth < 765 ? "mobile" : "desktop";
    params.limit = 8000;
    allGames.value = [];
    let copyAllGames;

    loading.value = true;

    if (params.categoryId === -1 && params.providers.length === 0) {
      state.providers = providers.value;
    } else {
      // copyAllGames = shallowReactive((await api.casino.games(params)) ?? []);
      copyAllGames = shallowReactive([]);
      if (params.categoryId === -1 || !isBackFromGame) {
        const gameProviders = [...new Set(allGames.value.map((game) => game.providerId))];
        state.providers = providers.value.filter((provider) => {
          return provider;
        });
      }
    }

    allGames.value = copyAllGames;

    loading.value = false;
  }

  return {
    state,
    categories,
    providers,
    games,
    allGames,
    categoriesExtended,
    loading,
    initCasino,
    updateGames,
  };
}
