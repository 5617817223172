<script>
import { onMounted, watch } from "vue";
import { useRoute } from "vue-router";
import BaseLayout from "@/app/layouts/BaseLayout.vue";

export default {
  name: "CasinoLayout",
  components: {
    BaseLayout,
  },
};
</script>

<template>
  <BaseLayout class="casino">
    <router-view />
  </BaseLayout>
</template>
