<script setup>
import { ref, watch } from "vue";
import { fetchProviders } from "../api";
import ProviderGrid from "@/casino/components/ProviderGrid.vue";

const props = defineProps({
  filters: {
    type: Object,
    required: true,
  },
});

const key = ref(0);

const providers = ref([]);

const pagination = ref({
  page: 1,
  rowsPerPage: 100,
});

watch(() => props.filters.search, () => {
  key.value++;
  providers.value = [];
  pagination.value.page = 1;
}, { deep: true });

async function loadProviders($state) {
  try {
    const params = {
      page: pagination.value.page,
      per_page: pagination.value.rowsPerPage,
    };

    if (props.filters.search) {
      params.search = props.filters.search;
    }

    const { data } = await fetchProviders(params);

    providers.value.push(...data.data);

    if (data.data.length < 100) {
      $state.complete();
    } else {
      $state.loaded();
    }
    pagination.value.page++;
  } catch (error) {
    $state.error();
  }
};
</script>

<template>
  <article>
    <ProviderGrid :key="key" :providers="providers" @load="loadProviders" />
  </article>
</template>
