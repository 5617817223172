<script setup>
import InfiniteLoading from "v3-infinite-loading";
import "v3-infinite-loading/lib/style.css";
import ProviderCard from "./ProviderCard.vue";

defineProps({
  providers: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(["load"]);

function onInfinite($state) {
  emit("load", $state);
}
</script>

<template>
  <div class="games-container">
    <div class="games-grid">
      <ProviderCard v-for="provider in providers" :key="provider.slug" :provider="provider" />
    </div>
  </div>
  <div class="infinite-loading">
    <InfiniteLoading style="width: min-content;" @infinite="onInfinite">
      <template #complete>
        <p />
      </template>
    </InfiniteLoading>
  </div>
</template>

<style lang="scss" scoped>
.infinite-loading {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.provider-header {
  margin-bottom: 10px;
  text-transform: capitalize;
}

.games-container {
  background-color: var(--be-big-card-bg);

  border-radius: 8px;
  margin-top: 10px;
}
.games-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(115px, 1fr));
  gap: 16px;
}

@media screen and (max-width: 550px) {
  .games-grid {
    padding: 0px;
    background: transparent;
    gap: 8px;
  }
}
</style>
