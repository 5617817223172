<script setup>
import { useRouter } from "vue-router";
import CasinoPanelFilters from "./CasinoPanelFilters.vue";
import BaseIcon from "@/app/components/ui/BaseIcon.vue";
import BaseButton from "@/app/components/ui/BaseButton.vue";

import { useRoute } from "@/app/router";

defineProps({
  categories: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(["search", "sort", "providers"]);

const route = useRoute();

const router = useRouter();

function handleCategory(slug) {
  if (slug === "home") {
    router.push({ name: "casino.lobby" });
    return;
  }

  if (slug === "providers") {
    router.push({ name: "casino.providers" });
    return;
  }

  router.push({ name: "casino.category", params: { slug } });
}

function isSelected(category) {
  if (route.name === "casino.lobby" && category.slug === "home") {
    return true;
  }

  if (route.name === "casino.providers" && category.slug === "providers") {
    return true;
  }

  return route.params.slug === category.slug;
}
</script>

<template>
  <section class="panel-wrapper">
    <div class="categories">
      <BaseButton
        v-for="category in categories"
        :key="category.id"
        class="category text"
        :class="{ active: isSelected(category) }"
        @click="() => handleCategory(category.slug)"
      >
        <BaseIcon
          v-if="category.icon"
          :name="category.icon"
          :filter="isSelected(category) ? 'drop-shadow(0px 0px 8px rgba(72, 255, 145, 0.5))' : ''"
          size="20"
        />
        <img
          v-else-if="category.image"
          :src="category.image.url"
          width="20px"
          height="20px"
          :style="{ filter: isSelected(category) ? 'drop-shadow(0px 0px 8px rgba(72, 255, 145, 0.5))' : '' }"
        >
        <BaseIcon
          v-else
          name="poker-cards"
          :filter="isSelected(category) ? 'drop-shadow(0px 0px 8px rgba(72, 255, 145, 0.5))' : ''"
          size="20"
        />
        <span class="category-name">{{ category.name }}</span>
      </BaseButton>
    </div>
  </section>
  <CasinoPanelFilters
    @search="emit('search', $event)"
    @sort="emit('search', $event)"
    @providers="emit('providers', $event)"
  />
</template>

<style lang="scss" scoped>
.categories {
  &::-webkit-scrollbar {
    height: 0px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.panel-wrapper {
  background-color: var(--be-big-card-bg);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;

  .mobile &,
  .tablet & {
    display: block;
  }

  .categories {
    display: flex;
    padding: 8px 10px 8px;
    overflow-y: auto;
    width: 100%;
    border-radius: 10px;

    .category {
      height: 63px;
      gap: 9px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-width: 137px;
      color: var(--be-disable-state);
      margin: 0 3px;
      font-weight: 600;
      font-size: 11px;
      letter-spacing: 1px;
      padding: 0px;
      font-family: Rubik;
      .mobile &,
      .tablet & {
        font-size: 10px;
      }

      .category-name {
        text-transform: none;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 1.2;
        font-size: 14px;
        font-family: Rubik;
        font-weight: 400;
      }

      img {
        max-width: 24px;
        height: 24px;
      }
    }

    @media screen and (max-width: 550px) {
      .category {
        width: auto;
        padding: 0 10px;
        gap: 7px;
        min-width: max-content;
      }
    }

    .active {
      opacity: 1;
      background: var(--be-small-card-bg);
      color: var(--be-primary-btn);
      border-radius: 6px;

      .category-name {
        filter: drop-shadow(0px 0px 8px rgba(72, 255, 145, 0.5));
      }
    }
  }

  @media screen and (max-width: 550px) {
    .categories {
      padding: 5px 8px 4px;
      margin-right: 0px;
    }
  }
}
</style>
