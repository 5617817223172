<script setup>
import { ref, onMounted, onUnmounted } from "vue";
import "vue-select/dist/vue-select.css";
import { fetchProviders } from "../api";

const emit = defineEmits(["providers"]);

const isMenuOpen = ref(false);
const menuRef = ref(null);
const loading = ref(false);

const providers = ref([]);
const selected = ref([]);

async function updateData() {
  loading.value = true;

  const { data } = await fetchProviders({ page: 1, per_page: 200 });
  providers.value = data.data;

  loading.value = false;
}

updateData();

function handleClickOutside(event) {
  if (menuRef.value && !menuRef.value.contains(event.target)) {
    isMenuOpen.value = false;
  }
}

onMounted(() => {
  document.addEventListener("click", handleClickOutside);
});

onUnmounted(() => {
  document.removeEventListener("click", handleClickOutside);
});

function toggleMenu() {
  isMenuOpen.value = !isMenuOpen.value;
}

function onProvidersChange(e, provider) {
  const id = provider.id;

  if (selected.value.includes(id)) {
    selected.value = selected.value.filter((item) => item !== id);
  } else {
    selected.value = [...selected.value, id];
  }

  emit("providers", selected.value);
}
</script>

<template>
  <div ref="menuRef" class="select-provider" :class="{ 'menu-open': isMenuOpen }">
    <div class="select-box">
      <div class="right-box" @click="toggleMenu">
        <div>
          Providers:
          <span class="selected-providers">
            {{ selected.length ? selected.length : "Any" }}
          </span>
        </div>
        <img
          class="arrow"
          :src="`/assets/images/${isMenuOpen ? 'up-arrow' : 'down-white'}.svg`"
          alt=""
        />
      </div>
    </div>
    <div v-if="isMenuOpen" class="select-menu">
      <div v-for="provider in providers" :key="provider.id" class="item">
        <label class="container">
          {{ provider.name }}

          <input
            type="checkbox"
            :checked="selected.includes(provider.id)"
            @change="(e) => onProvidersChange(e, provider)"
          />
          <span class="checkmark" />
        </label>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.selected-providers {
  color: var(--be-primary-btn);
  margin-left: 0.5rem;
}
.provider-selector {
  background-color: var(--be-small-card-bg);
  border-radius: 4px;
  margin-left: 10px;
  min-width: 170px;
  padding-top: 4px;
  .tablet & {
    max-width: unset;
  }
}

.option {
  display: flex;
  align-items: baseline;
  padding: 3px 7px;
  white-space: nowrap;
}
</style>

<style lang="scss">
.provider-selector {
  .vs__dropdown-option--deselect {
    background: unset;
    color: unset;
  }
  .vs__dropdown-option--selected {
    background-color: #ccc;
  }

  .not-empty .v-select__selections input {
    display: none;
  }
  .v-input__icon--append .v-icon {
    transform: none !important;
  }
  .vs__search {
    cursor: pointer;
  }
  .vs__selected {
    color: #333;
    background: rgb(255, 232, 26);
    box-shadow: rgba(255, 176, 25, 0.4) 0px 0px 10px, rgba(255, 255, 255, 0.2) 0px 1px 0px inset,
      rgba(0, 0, 0, 0.15) 0px -3px 0px inset, rgb(255, 135, 25) 0px 0px 12px inset;
    /* max-width: 100px; */
  }
  .vs__deselect {
    color: #555;
    min-width: 10px;
  }
  .vs__actions {
    min-width: 25px;
    height: 35px;
    cursor: pointer;
  }
  .vs__dropdown-menu {
    left: unset;
    right: 0;
    overflow-x: hidden;
    width: fit-content;
    top: calc(100% + 7px);
    border-radius: 3px;
  }
  .vs__selected:has(> .hidden) {
    display: none;
  }
  .vs__open-indicator {
    fill: white;
  }
}

.select-provider {
  position: relative;
  margin-left: 16px;
}

.arrow {
  width: 16px;
}
.right-box {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 12px;
  font-family: Rubik;
  font-weight: 400;
}

.select-box {
  border-radius: 8px;
  border: 1px solid var(--be-divider);
  background: var(--be-big-card-bg);
  display: flex;
  align-items: center;
  font-size: 15px;
  height: 47px;
  min-width: 250px;
  cursor: pointer;
  transition: border-color 0.2s ease;

  &:hover {
    border-color: var(--be-primary-btn);
  }

  .select-provider.menu-open & {
    border-color: var(--be-primary-btn);
  }

  .left-icon {
    cursor: pointer;
    background: var(--be-secondary-btn);
    height: 100%;
    display: flex;
    align-items: center;
    padding: 16px;
    margin-right: 15px;
  }
}

.select-menu {
  border-radius: 8px;
  border: 1px solid var(--be-small-card-bg);
  background: var(--be-big-card-bg);
  box-shadow: 0px 30px 80px 0px rgba(0, 0, 0, 0.25);
  position: absolute;
  right: 0;
  padding: 16px;
  width: 100%;
  height: 350px;
  overflow-y: auto;
  margin-top: 9px;
  z-index: 7;

  .col {
    margin-bottom: 12px;
  }

  .item {
    display: flex;
    flex-basis: 33.33%;
    gap: 0px 6px;
    margin-bottom: 14px;

    .container {
      display: block;
      position: relative;
      padding-left: 23px;
      cursor: pointer;
      font-size: 22px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      font-family: Rubik;
      padding-top: 2px;

      color: var(--White, #fff);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    /* Hide the browser's default checkbox */
    .container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
      position: absolute;
      top: 1px;
      left: 0;
      height: 18px;
      width: 18px;
      border-radius: 4px;
      background-color: var(--be-small-card-bg);
      border: 1px solid #333360;
    }

    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark {
      background-color: var(--be-small-card-bg);
    }

    /* When the checkbox is checked, add a blue background */
    .container input:checked ~ .checkmark {
      background-color: var(--be-primary-btn);
      border: 1px solid var(--be-primary-btn);
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    /* Show the checkmark when checked */
    .container input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the checkmark/indicator */
    .container .checkmark:after {
      left: 5.5px;
      top: 2px;
      width: 5px;
      height: 9px;
      border: solid var(--be-primary-btn-label);
      border-width: 0 1.5px 1.5px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}

@media screen and (max-width: 500px) {
  .select-provider {
    position: relative;
    margin-left: 0px;
    width: 50%;
  }

  .select-menu {
    width: 100%;
    transform: translate(-50%, 0%);
    left: 50%;
    .item {
      flex: 50%;
    }
  }
  .select-box {
    min-width: 50%;
    height: 47px;
    border-radius: 8px;
    .left-icon {
      cursor: pointer;
      margin-right: 0px;
      background: transparent;
      padding: 0px 8px 0 10px;

      .left-img {
        width: 16px;
      }
    }

    .right-box {
      width: 100%;
    }
  }
}
</style>
