<script setup>
import { defineEmits } from "vue";
import ProviderSelector from "./ProviderSelector.vue";
import SortSelector from "./SortSelector.vue";
import BaseIcon from "@/app/components/ui/BaseIcon.vue";
import { useRoute } from "@/app/router";

const emit = defineEmits(["search", "sort", "providers"]);

const route = useRoute();

function onSearch(value) {
  emit("search", value);
}

function onProviders(value) {
  emit("providers", value);
}
</script>

<template>
  <div class="filters">
    <div
      class="search"
    >
      <div class="prepend-inner">
        <BaseIcon name="magnify" size="20" />
      </div>
      <input
        aria-autocomplete="none"
        placeholder="Search"
        type="text"
        class="search-input"
        @input="onSearch($event.target.value)"
      >
    </div>

    <div
      v-if="route.path != '/casino/home' && route.path != '/casino/home/providers'"
      class="provider-select-wrapper"
    >
      <ProviderSelector @providers="onProviders" />
      <SortSelector />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.filters {
  width: 100%;
  margin-top: 10px;
  display: flex;
  .search {
    background-color: var(--be-big-card-bg);
    display: flex;
    gap: 10px;
    border: 1px solid var(--be-divider);
    border-radius: 8px;
    height: 47px;
    transition: border-color 0.2s ease;
    flex-grow : 1;

    &:hover {
      border-color: var(--be-primary-btn);
    }

    &:focus-within {
      border-color: var(--be-primary-btn);
    }
  }

  .search-full {
    flex-basis: 100%;
  }

  .search-input {
    width: 100%;
    outline: none;
    font-weight: 400;
    font-family: Rubik;
  }
  input::placeholder {
    color: var(--be-disable-state);
  }
  .prepend-inner {
    display: flex;
    align-items: center;
    border-radius: 8px 0 0 8px;
    padding: 0px 0 0 10px;
    margin-right: 5px;
    color: var(--be-disable-state);
  }

  .provider-select-wrapper {
    display: flex;
  }
}

@media screen and (max-width: 550px) {
  .filters {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    gap: 10px;
  }
}
</style>
